export function findHighestLowest(array) {
	let highest = array[0];
	let lowest = array[0];

	for (let i = 1; i < array.length; i++) {
		if (array[i] > highest) {
			highest = array[i];
		}
		if (array[i] < lowest) {
			lowest = array[i];
		}
	}
	return { highest, lowest };
}
